*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body > iframe {
  pointer-events: none;
}

/* ======================== Form Details Styling   =================================== */
:root {
  --primary-color: #0AA5B7;
  --text-primary: #333;
  --text-secondary: #666;
  --border-color: #E0E0E0;
}

.formDetailsRoot {
  width: 100%;
  max-width: 457px;
  margin: 0 auto;
  padding-right: 16px;
}

.radio .MuiSvgIcon-root {
  height: 15px;
  width: 15px;
  color: var(--primary-color);
}

.fieldTitle {
  margin-bottom: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.fieldTitleSecondary {
  color: var(--text-primary);
  padding-bottom: 8px;
  font-size: 1rem !important;
}

.icon {
  height: 30px;
  width: auto;
}

.marker {
  font-weight: bold;
  font-size: 20px;
  color: red;
  transform: rotate(0.1deg);
}

.form {
  display: flex;
  flex-direction: column;
}

.formSection {
  border: 1px solid var(--border-color);
  padding: 16px;
  padding-bottom: 37px;
}

.fromControl {
  margin-bottom: 15px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .formSection {
      border: none;
      padding: 8px;
  }
}

.formHeader {
  max-width: 457px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.radioBlockWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fieldset {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center;
  justify-content: space-between;
}

.inputWrapper {
  padding-bottom: 16px;
}

.input {
  min-width: 100%;
}

.input .MuiInputBase-input {
  border-radius: 0;
  padding: 0 8px;
  font-size: 1rem;
  width: 100%;
  height: 48px;
}

.input .MuiOutlinedInput-root {
  border-radius: 0;
}

.input .MuiOutlinedInput-root fieldset {
  border: 0.5px solid var(--border-color);
  border-radius: 3px;
}

.input .MuiOutlinedInput-root:hover fieldset {
  border: 1px solid var(--primary-color);
}

.input .MuiOutlinedInput-root.Mui-focused fieldset {
  border: 1px solid var(--primary-color);
}

.checkBox {
  padding-bottom: 4px;
}

.checkBox .MuiCheckbox-root {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.checkBox-wrapper {
  padding-bottom: 32px;
}

.link .MuiLink-root {
  color: var(--text-secondary);
  text-decoration: none;
}

.button {
  padding-top: 32px;
  padding-left: 12px;
}

.mobileMoneyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mobileMoneyHeaderTitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-primary);
}

.changeButton {
  padding: 10px 15px;
  border: none;
  background: rgba(10, 165, 183, 0.1);
  color: var(--primary-color);
  border-radius: 3px;
  font-weight: 500;
}

.changeButton:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}


/* ======================== Form Details Styling   =================================== */

