.MuiTable-root .cardGridRoot {
    width: fit-content;
    padding-left: 0;
    padding-right: 0;
}

.row .MuiTableRow-root {
    padding-top: 20px; /* theme.spacing(2.5) */
    padding-bottom: 12px; /* theme.spacing(1.5) */
    margin-right: 24px; /* theme.spacing(3) */
    margin-left: 24px; /* theme.spacing(3) */
}

.tableHeader .MuiTableCell-root {
    font-size: 1rem;
    line-height: 1.5rem;
    vertical-align: top;
    width: 100px;
    padding-top: 0;
}

.tableHeader th:nth-child(1),
.tableHeader th:nth-child(3) {
    width: 30px !important;
    max-width: 30px !important;
    text-align: left;
}

.tableHeader .MuiTypography-root {
    font-weight: 600;
    font-size: 0.9rem;
    text-align: inherit;
}

.tableCell {
    font-size: 1rem;
}
