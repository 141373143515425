.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border-radius: 4px;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
}

.button {
    margin-top: 2px;
    width: 100%;
    padding: 20px !important
}

.span {
    font-weight: 600;
}

.imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
}

.centerText {
    text-align: center;
}

.spinnerStyle {
    animation: spin 1s linear infinite;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .box {
        width: 90%;
        padding: 18px;
    }
}
