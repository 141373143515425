.languageBox {
    min-width: 80px;
    min-height: auto;
}

.languageSelect {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: inherit;
}

.languageFormControl {
    height: auto;
}

.languageIcon {
    width: auto;
}

.languageSelect2 {
    height: auto;
    margin-top: auto;
    padding: 0;
}

@media (max-width: 600px) {
    .languageBox {
        min-width: 60px;
        min-height: 40px;
        position: absolute;
        right: 10px;
        top: 20px;
    }

    .languageSelect {
        font-size: 12px;
    }

    .languageFormControl {
        height: 20px;
    }

    .languageIcon {
        width: 15px;
    }

    .languageSelect2 {
        height: 25px;
        margin-top: 33px;
        width: 90px;
    }
}
