
.cardHeader {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 20px;
}

.gridWrapper {
    padding-right: 24px;
    padding-left: 24px;
}

.cardTotal {
    display: flex;
    padding: 20px 28px;
    justify-content: space-between;
    border-bottom: none;
    background-color: var(--primary-color);
    color: var(--background-default);
    border: 2px solid var(--primary-color);
}

.footer {
    background-color: var(--primary-color);
    padding-left: 24px;
    padding-right: 24px;
}

/* Responsive Design */
@media (max-width: 600px) {
    .gridWrapper {
        padding-right: 16px;
        padding-left: 16px;
    }
    
    .cardTotal {
        padding: 16px 20px;
    }

    .footer {
        padding-left: 16px;
        padding-right: 16px;
    }
}