:root {
  --backgroundLight: #F5F5F5; /* Adjust based on your theme */
}

.headerRoot {
  margin: 0 auto;
  height: 86px;
  display: flex;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  background-color: var(--backgroundLight);
}

.container {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-basis: 66.666667%;
}

.logo {
  height: 100%;
  width: 187px;
}

.rightPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: right;
  align-items: center;
}

.button {
  font-size: 0.875rem !important;
  min-width: 1rem !important;
  height: 24px;
}

.poweredBy {
  width: 158px;
  height: 100%;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .root {
      height: 85px;
  }

  .container {
      flex-basis: 100%;
  }

  .logo {
      width: 137px;
  }

  .rightPart {
      flex-direction: column;
      gap: 0;
  }

  .poweredBy {
      width: 120px;
  }
}
